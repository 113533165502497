import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Insights from "../molecules/Insights";

// Components
import StaffingHeader from "../molecules/staffing/staffingHeader";
import StaffingDescription from "../molecules/staffing/staffingDescription";

import StaffingModelOfEngagement from "../molecules/staffing/staffingModelOfEngagement";
// import ContactForm from "../../molecules/ContactForm";

import ImageBanner from "../components/atoms/banner/imageBanner";
import { useStaticQuery, graphql } from "gatsby";

//images
import bannerImg from "../images/servicesassets/staffing.png";
import descImg from "../images/servicesassets/staffing-description.png";
import { Zoom } from "react-reveal";
import SolutionsHeader from "../molecules/solutions/SolutionsHeader";
import StaffingNewTimelineComponent from "../molecules/staffing/staffingNewTimelineComponent";
import MobileIn from "../images/servicesassets/banners/StaffingMobileIn.png";
import MobileEn from "../images/servicesassets/banners/StaffingMobileEn.png";
import MobileJa from "../images/servicesassets/banners/StaffingMobileJa.png";
import { useLanguage } from "../languageContext";
const StaffingPage = () => {
  const data = useStaticQuery(graphql`
    query GetStaffingData {
      gcms {
        servicePages(where: { pageType: STAFFING }) {
          heading
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
        }
      }
    }
  `);
  const currentLanguage = useLanguage();

  return (
    <Layout isNavWhite={true} hideMap>
      <SEO
        canonicalLink={"https://www.goken-global.com/staffing/"}
        title="Staffing Services: Staffing Solutions by Goken"
        description={
          "Connect with top talent and build your dream team with Goken's staffing services. Specializing in engineering, IT, and more. Click to start hiring!"
        }
        // meta="Staffing Services, Staffing, Staffing agency, temp agency, recruitment agencies, staffing agencies near me, employment agencies, staffing solutions"
        facebookTitle={
          "Goken | We Provide Engineering Staffing Solutions in India and USA"
        }
        facebookDescription={
          "Goken Engineering Staffing Solutions in India and USA makes sure that hiring the right candidate helped us to expand our roots in the auto industry to aircraft, medical devices, consumer products, and many other industries. For 15 years we have had a unique approach in the staffing and selection process."
        }
        keywords={"engineering staffing solutions"}
      />
      {/* <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
      ></StaffingHeader> */}
      <SolutionsHeader
        headingOne={data.gcms.servicePages[0].heading}
        // headingTwo={"Boost Team Productivity and Output"}
        paragraph={data.gcms.servicePages[0].paragraph}
        btnText="Talk To An Expert"
        page="staffing"
        mobileImage={
          currentLanguage === "en"
            ? MobileEn
            : currentLanguage === "in"
            ? MobileIn
            : currentLanguage === "ja"
            ? MobileJa
            : MobileEn
        }
        mobileAltText="Staffing Solutions Mobile Graphic"
      />

      <StaffingDescription
        headingOne="HOW GOKEN SOLVES TALENT MANAGEMENT CHALLENGES ACROSS MULTIPLE INDUSTRIES"
        description={data.gcms.servicePages[0].description}
        image={descImg}
      ></StaffingDescription>
      <StaffingNewTimelineComponent
        heading="OUR PROCESS"
        bannerService={"Staffing"}
        tagline="Goken provides access to a larger  talent pool of candidates and reduces the time to fill"
        items={data.gcms.servicePages[0].timelineItems}
        style={"staffing"}
      />

      <div className="staffing-moe">
        <StaffingModelOfEngagement
          heading="MODELS OF ENGAGEMENT"
          modals={data.gcms.servicePages[0].sliderModals}
        ></StaffingModelOfEngagement>
      </div>
      <div className="w-90-wrapper">
        <Insights
          showTitle={true}
          insights={data.gcms.servicePages[0].insightPages}
        ></Insights>
      </div>
      {/* <ContactForm
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      <ImageBanner
        heading="Learn more about our Staffing Solutions"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default StaffingPage;
